import { useEffect } from "react";
import { useParams } from "react-router-dom"
import { useSideSheetStore } from "../../store/side_sheet_store"
import AddEmployee from "../teams/add_member";
import { useTranslation } from "react-i18next";

const Invite = () => {
    const { showOpen } = useSideSheetStore()
    const { email } = useParams()

    const { t } = useTranslation();
    const add = () => showOpen(true, t('add_employee'), <AddEmployee email={email} />)

    useEffect(() => { add() }, [])

    return (
        <div className="px-28 text-center flex h-screen">
            <div className="m-auto">
                <div className="text-xl font-semibold pb-4">
                    Fill in the fields to create your employee account
                </div>

                <button
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-blue-300 bg-white px-4 py-2 text-base font-medium text-blue-700 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:text-sm"
                    onClick={add}>
                    Open Form
                </button>
            </div>
        </div>
    )
}

export default Invite