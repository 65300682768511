import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

const IndexRoute = () => {
    const { user } = useUserAuth();

    const [role, setRole] = useState(null)

    if (!user) {
        return <Navigate to="/login" />;
    }

    return <Navigate to="/dashboard" />
};

export default IndexRoute;