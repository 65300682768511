import { lazy } from 'react'
import ProtectedRoute from "../components/ProtectedRoute"
import Invite from "../pages/invite";

const Calendar = lazy(() => import('../pages/calendar'))
const Teams = lazy(() => import('../pages/teams'))
const Deductions = lazy(() => import('../pages/deductions'))
const Department = lazy(() => import('../pages/department'))
const Documents = lazy(() => import('../pages/documents'))
const Report = lazy(() => import('../pages/reports'))
const JobPage = lazy(() => import('../pages/job'))
const OverView = lazy(() => import('../pages/overview'))
const Employees = lazy(() => import('../pages/employees'))
const Settings = lazy(() => import('../pages/settings'))
const Requests = lazy(() => import('../pages/requests'))
const Chat = lazy(() => import('../pages/chats'))
const Clients = lazy(() => import('../pages/employees/clients'))
const ServiceProviders = lazy(() => import('../pages/employees/service providers'))

// Dynamic routes
const DynamicDepartment = lazy(() => import('../pages/dynamics/department'))
const DynamicTeam = lazy(() => import('../pages/dynamics/team'))
const DynamicEmployee = lazy(() => import('../pages/dynamics/employee'))
const DynamicRequest = lazy(() => import('../pages/dynamics/request'))
const DynamicSession = lazy(() => import('../pages/dynamics/session'))
const DynamicApplication = lazy(() => import('../pages/dynamics/application'))
const DynamicRating = lazy(() => import('../pages/dynamics/rating'))

export const AdminRoutes = [
    { path: "calendar", element: <ProtectedRoute children={<Calendar />} /> },
    { path: "team", element: <ProtectedRoute children={<Teams />} />, },
    { path: "team/:teamId", element: <ProtectedRoute children={<DynamicTeam />} /> },
    { path: "deductions", element: <ProtectedRoute children={<Deductions />} />, },
    { path: "documents", element: <ProtectedRoute children={<Documents />} />, },
    { path: "report", element: <ProtectedRoute children={<Report />} />, },
    { path: "department", element: <ProtectedRoute children={<Department />} />, },
    { path: "department/:departmentId", element: <ProtectedRoute children={<DynamicDepartment />} /> },
    { path: "jobs", element: <ProtectedRoute children={<JobPage />} /> },
    { path: "overview", element: <ProtectedRoute children={<OverView />} /> },
    { path: "clients", element: <ProtectedRoute children={<Clients />} /> },
    { path: "service-providers", element: <ProtectedRoute children={<ServiceProviders />} /> },
    { path: "employees", element: <ProtectedRoute children={<Employees />} /> },
    { path: "employee/:employeeId", element: <ProtectedRoute children={<DynamicEmployee />} /> },
    { path: "employee/application/:employeeId", element: <ProtectedRoute children={<DynamicApplication />} /> },
    { path: "employee/rating/:ratingId", element: <ProtectedRoute children={<DynamicRating />} /> },
    { path: "settings", element: <ProtectedRoute children={<Settings />} /> },
    { path: "requests", element: <ProtectedRoute children={<Requests />} /> },
    { path: "request/:requestId", element: <ProtectedRoute children={<DynamicRequest />} /> },
    { path: "chats", element: <ProtectedRoute children={<Chat />} /> },
    { path: "invite/:email", element: <ProtectedRoute children={<Invite />} /> },
    { path: "session/:sessionId", element: <ProtectedRoute children={<DynamicSession />} /> },
]