import { lazy } from 'react'
import ClientRoute from "../components/ClientRoute"
import UnProtectedRoute from "../components/UnprotectedRoute";

const ClientLogin = lazy(() => import('../pages/client-dashboard/login'))
const ClientRegister = lazy(() => import('../pages/client-dashboard/register'))
const ClientPayment = lazy(() => import('../pages/client-dashboard/payment'))
const ClientHome = lazy(() => import('../pages/client-dashboard/home'))
const ClientBook = lazy(() => import('../pages/client-dashboard/book'))
const SessionCreated = lazy(() => import('../pages/client-dashboard/book/created'))
const SessionFinished = lazy(() => import('../pages/client-dashboard/book/pay'))
const SessionPayCompleted = lazy(() => import('../pages/client-dashboard/book/pay_completed'))
const SessionRating = lazy(() => import('../pages/client-dashboard/book/rating_normal'))
const SessionRatingCompleted = lazy(() => import('../pages/client-dashboard/book/rating_completed'))
const ClientDashboard = lazy(() => import('../pages/client-dashboard'))
const ClientSessions = lazy(() => import('../pages/client-dashboard/sessions'))
const ClientSessionDetail = lazy(() => import('../pages/client-dashboard/sessions/detail'))

const ClientProfile = lazy(() => import('../pages/client-dashboard/profile'))
const CustomerCare = lazy(() => import('../pages/client-dashboard/customer-care'))
const CustomerMessage = lazy(() => import('../pages/client-dashboard/message'))
const ClientAbout = lazy(() => import('../pages/client-dashboard/about'))
const ClientSettings = lazy(() => import('../pages/client-dashboard/settings'))
const PaymentValidated = lazy(() => import('../pages/client-dashboard/payment/validated'))
const PayDetails = lazy(() => import('../pages/client-dashboard/book/pay'))
const ClientPay = lazy(() => import('../pages/client-dashboard/pay-job/index'))

export const ClientRoutes = [
    { path: "login", element: <UnProtectedRoute children={<ClientLogin />} /> },
    { path: "register", element: <UnProtectedRoute children={<ClientRegister />} /> },
    { path: "payment/verification", element: <ClientRoute children={<ClientPayment />} /> },
    { path: "payment/validated", element: <ClientRoute children={<PaymentValidated />} /> },
    { path: "home", element: <ClientRoute children={<ClientHome />} /> },
    { path: "book", element: <ClientRoute children={<ClientBook />} /> },
    { path: "book/created", element: <ClientRoute children={<SessionCreated />} /> },
    { path: "book/finished", element: <ClientRoute children={<SessionFinished />} /> },
    { path: "book/pay/:sessionId", element: <ClientRoute children={<PayDetails />} /> },
    { path: "book/pay/finished", element: <ClientRoute children={<SessionPayCompleted />} /> },
    { path: "book/rate/:sessionId", element: <ClientRoute children={<SessionRating />} /> },
    { path: "book/rate/:sessionId/completed", element: <ClientRoute children={<SessionRatingCompleted />} /> },
    { path: "dashboard", element: <ClientRoute children={<ClientDashboard />} />, },
    { path: "sessions", element: <ClientRoute children={<ClientSessions />} />, },
    { path: "sessions/:sessionId", element: <ClientRoute children={<ClientSessionDetail />} /> },
    { path: "pay/:sessionId", element: <ClientRoute children={<ClientPay />} /> },

    { path: "profile", element: <ClientRoute children={<ClientProfile />} /> },
    { path: "customer-care", element: <ClientRoute children={<CustomerCare />} />, },
    { path: "message", element: <ClientRoute children={<CustomerMessage />} />, },
    { path: "about", element: <ClientRoute children={<ClientAbout />} />, },
    { path: "settings", element: <ClientRoute children={<ClientSettings />} /> },
]