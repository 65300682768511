import { useEffect } from "react"
import { getAccount } from "../../services/onboard"

const ReAuth = () => {
    useEffect(() => {
        getLink()
    }, [])

    const getLink = async () => {
        var res = await getAccount()
        window.location.replace(res.data.url)
    }

    return (
        <div>Loading...</div>
    )
}

export default ReAuth