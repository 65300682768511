import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

const UnProtectedRoute = ({ children }) => {
    var CLIENT_LOGIN = '/client/login'
    const { user } = useUserAuth();
    const { pathname } = useLocation()

    const noUser = user === null || user === {}

    if (noUser || pathname === CLIENT_LOGIN) {
        return children;
    }

    return <Navigate to="/" />;
};

export default UnProtectedRoute;