import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

const ClientRoute = ({ children }) => {
    const { user } = useUserAuth();
    let location = useLocation();
    const [role, setRole] = useState(null)

    const sam = async () => {
        var { claims } = await user.getIdTokenResult()
        const { role } = claims
        setRole(role)
    }

    useEffect(() => { sam() }, [])

    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (user && role == 'Client') {
        return children;
    }

    return <></>
};

export default ClientRoute;