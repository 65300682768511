import { functions, db } from './initialise'
import {
    httpsCallable,
} from 'firebase/functions'
import { collection, getDocs, query, orderBy, where, limit, updateDoc, doc, setDoc, deleteDoc } from 'firebase/firestore'

export const updateStaffMetadata = () => {
    var callable = httpsCallable(functions, 'updateStaff')
    return callable();
}

export const createStaff = (staff, team, department) => {
    const {
        firstName,
        lastName,
        phone,
        role,
        email,
        sin,
    } = staff
    var callable = httpsCallable(functions, 'addSingleStaff');
    var data = {
        name: firstName + ' ' + lastName,
        phone,
        role,
        email,
        sin,
        team,
        department,
        first_name: firstName,
        last_name: lastName,
    };

    return callable(data);
}

export const checkRoles = () => {
    var callable = httpsCallable(functions, 'checkRoles')
    return callable();
}

export const getRoles = () => {
    const ref = collection(db, 'Roles')
    const q = query(ref, orderBy('name', 'asc'))
    return getDocs(q);
}

export const getTeamMemebersQuery = () => {
    const ref = collection(db, 'Staffs')
    const q = query(ref, where('role', '!=', 'Client'))
    return q;
}

export const getStatusSP = (status) => {
    const ref = collection(db, 'Staffs')
    const q = query(ref, where('status', '==', status), orderBy('name', 'asc'))
    return q;
}

export const toggleTermination = (uid, val) => {
    const ref = doc(db, "Staffs", uid)
    return updateDoc(ref, { isTerminated: val })
}

export const getAllStaffs = () => {
    const ref = collection(db, 'Staffs')
    const q = query(ref, orderBy('name', 'asc'))
    return getDocs(q);
}

export const addTeam = (data) => {
    const { name } = data
    const ref = doc(db, "Teams", name)
    return setDoc(ref, data, { merge: true })
}

export const deleteTeam = (name) => {
    const ref = doc(db, "Teams", name)
    return deleteDoc(ref)
}

export const getTeamQuery = () => {
    const ref = collection(db, 'Teams')
    const q = query(ref, orderBy('name', 'asc'))
    return q;
}

export const getAllRef = () => {
    const ref = collection(db, 'Staffs')
    const q = query(ref, orderBy('name', 'asc'))
    return q;
}