import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { useSideSheetStore } from '../../store/side_sheet_store'
import { createStaff, getRoles } from '../../services/team'
import { toast } from "react-toastify";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from "../../helper/classes";
import Button from "../../components/button";
import { getPositions } from '../../services/clients'
import { onValue } from 'firebase/database'

const AddEmployee = ({ team, department, email }) => {
    const [people, setPeople] = useState([])
    const [selected, setSelected] = useState({})
    const [data, setData] = useState({
        firstName: null,
        lastName: null,
        phone: null,
        email: email,
        sin: null,
        role: null
    })

    useEffect(() => {
        const unSub = onValue(
            getPositions(),
            (snapshot) => {
                var json = snapshot.toJSON()
                var first = Object.keys(json)[0]
                snapshot.forEach((snap) => setPeople((s) => [...s, snap.val()]))
                setSelected(json[first])
                setData(prev => ({ ...prev, role: json[first] }))
            }, { onlyOnce: true, }
        );

        return () => {
            unSub()
        }
    }, [])

    const { t } = useTranslation();
    const { setOnlyOpen } = useSideSheetStore()

    const handleChange = (e) => {
        const { name, value } = e.target
        setData(prev => ({ ...prev, [name]: value }))
    }

    const clear = () =>
        setData(s => ({ email: '', firstName: '', lastName: '', phone: '', role: '', sin: '', }))


    const saveFinish = async () => {
        try {
            await createStaff(data, team, department)
            clear()
            setOnlyOpen(false)
        } catch (e) {
            toast(e.message)
        }
    }

    const saveAnother = async () => {
        try {
            await createStaff(data, team, department)
            clear()
            toast(t('staff_added'))
        } catch (e) {
            toast(e.message)
        }
    }

    return <>
        <div className="flex gap-3">
            <div>
                <label htmlFor="firstName" className="ml-1 block text-sm font-medium text-gray-700 mb-2">
                    {t('first_name')}
                </label>

                <input
                    onChange={handleChange}
                    id="firstName"
                    name="firstName"
                    type={"text"}
                    className="rounded border-1 h-12 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    placeholder={"Marcus"}
                    defaultValue={''}
                />
            </div>
            <div>
                <label htmlFor="lastName" className="ml-1 block text-sm font-medium text-gray-700 mb-2">
                    {t('last_name')}
                </label>
                <input
                    onChange={handleChange}
                    id="lastName"
                    name="lastName"
                    type={"text"}
                    className="rounded border-1 h-12 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    placeholder={"Samson"}
                    defaultValue={''}
                />
            </div>
        </div>

        <div className="ml-1 text-base font-medium pt-5">{t('contact_information')}</div>

        <div className="flex gap-3 py-4">
            <div>
                <label htmlFor="email" className="ml-1 block text-sm font-medium text-gray-700 mb-2">
                    {t('email')}
                </label>

                <input
                    onChange={handleChange}
                    id="email"
                    name="email"
                    type={"email"}
                    className="rounded border-1 h-12 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    placeholder={"marcus@royalfountain.com"}
                    defaultValue={''}
                />
            </div>
            <div>
                <label htmlFor="phone" className="ml-1 block text-sm font-medium text-gray-700 mb-2">
                    {t('phone_number')}
                </label>
                <input
                    onChange={handleChange}
                    id="phone"
                    name="phone"
                    type={"tel"}
                    className="rounded border-1 h-12 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    placeholder={"+2341234567"}
                    defaultValue={''}
                />
            </div>
        </div>

        <div>
            <label htmlFor="sin" className="ml-1 block text-sm font-medium text-gray-700 mb-2">
                SIN
            </label>

            <input
                onChange={handleChange}
                id="sin"
                name="sin"
                type={"text"}
                className="rounded border-1 h-12 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                placeholder={"A0000122"}
                defaultValue={''}
            />
        </div>

        <div className="ml-1 text-base font-medium pt-5">{t('role')}</div>

        {people.length > 0 && <Listbox value={selected.name} onChange={setSelected}>
            {({ open }) => (
                <>
                    <div className="relative mt-1">
                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                            <span className="flex items-center">
                                <span className="block truncate">{selected.name}</span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {people.map((person) => (
                                    <Listbox.Option
                                        key={person.id}
                                        onClick={() => setData(prev => ({ ...prev, role: person.name }))}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={person}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    <span
                                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                                                    >
                                                        {person.name}
                                                    </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-blue-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>}

        <div className="pt-8">
            <Button
                text={t('save_finish')}
                type="button"
                onClick={saveFinish}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
            />

            <div className="pt-3">
                <Button
                    text={t('save_add_another')}
                    type="button"
                    onClick={saveAnother}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-blue-300 bg-white px-4 py-2 text-base font-medium text-blue-700 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:text-sm"
                />
            </div>
        </div>
    </>
}

export default AddEmployee