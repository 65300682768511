import { RouterProvider } from "react-router-dom";
import { router } from './routes'
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { ToastContainer } from 'react-toastify';
import { Suspense } from 'react'
import Loader from "./components/animation";

const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }

function App() {
  return <>
    <UserAuthContextProvider>
      <Suspense fallback={<Loader style={style} />}>
        <RouterProvider router={router} />
      </Suspense>
    </UserAuthContextProvider>
    <ToastContainer hideProgressBar={true} position={'bottom-left'} theme={'dark'} />
  </>;
}

export default App;
