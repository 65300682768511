import { firebaseConfig } from '../constant/config'
import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import { getDatabase } from 'firebase/database'
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"
import { getMessaging } from "firebase/messaging"
import { getStorage } from "firebase/storage"

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app)
export const messaging = getMessaging(app)
export const storage = getStorage(app)
export const rdb = getDatabase(app)

export default app;