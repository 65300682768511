import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  getIdTokenResult
} from "firebase/auth";
import { auth } from "../services/initialise";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  const logIn = async (email, password) => {
    var { user } = await signInWithEmailAndPassword(auth, email, password);
    return user;
  }

  const signUp = async (email, password, name) => {
    var cred = await createUserWithEmailAndPassword(auth, email, password);
    var user = cred.user;
    await updateProfile(user, { displayName: name })
    return user;
  }

  const sendEmailToVerify = () => sendEmailVerification(auth.currentUser)

  const resetPassword = (email) => sendPasswordResetEmail(auth, email)

  const token = () => getIdTokenResult(user)

  function logOut() {
    return signOut(auth);
  }

  const currentUser = () => auth.currentUser;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      // console.log("Auth", currentuser);
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut, sendEmailToVerify, resetPassword, currentUser, token }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}